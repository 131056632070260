import { render, staticRenderFns } from "./SectionBuilding.vue?vue&type=template&id=0c7c440a&"
import script from "./SectionBuilding.vue?vue&type=script&lang=js&"
export * from "./SectionBuilding.vue?vue&type=script&lang=js&"
import style0 from "./SectionBuilding.vue?vue&type=style&index=0&id=0c7c440a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/SectionBuilding.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Fsections%2Fproject%2FSectionBuilding.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsProjectBuildingOrganism3DContainer: require('/app/components/organisms/project/building/Organism3DContainer.vue').default,OrganismsProjectBuildingOrganismGuidedTourPresentationDrawer: require('/app/components/organisms/project/building/OrganismGuidedTourPresentationDrawer.vue').default,OrganismsProjectBuildingOrganismProjectUI: require('/app/components/organisms/project/building/OrganismProjectUI.vue').default,OrganismsProjectBuildingOrganismSpace: require('/app/components/organisms/project/building/OrganismSpace.vue').default,AtomsCommonAtomIcon: require('/app/components/atoms/common/AtomIcon.vue').default})
