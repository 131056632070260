
export default {
  name: 'SectionGalleryV2',
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    }
  },
  computed: {
    activeProject() {
      return this.$store.state.project.activeProject
    },
    matterport() {
      return this.$store.state.base.meta.generalConfig.matterportEnabled
    },
    currentSpace() {
      if (!this.$store.state.building.space) return {}
      return this.$store.state.building.space.spaceData
    },
    spaceFloor() {
      return (
        this.$store.state.project.project.floors.find((f) => f.id === this.currentSpace.floor_id) ||
        {}
      )
    },
    content() {
      return this.data
    },
    images() {
      return this.data.images
    },
    videos() {
      return this.data.videos
    }
  },
  methods: {
    async download(url, filename) {
      const projectConstants = this.$store.getters.constants.project
      const { result, error } = await this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.DOWNLOAD_FILE),
        {
          id: this.currentSpace.id,
          project_slug: this.activeProject.slug
        }
      )

      if (error) {
        console.log(error)
      } else {
        const link = document.createElement('a')
        link.setAttribute('id', 'download-link')
        link.setAttribute('href', result)
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    resourceUrl(img) {
      if (img.includes('://')) {
        return img
      }
      return `${this.$store.getters.cdnBase}/${img}`
    }
  }
}
